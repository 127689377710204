.bookingHead1 {
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

.bookingHead2 {
  font-size: 20px;
  font-weight: 600;
  color: rgba(33, 136, 33, 1);
}

.bookingHead3 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.bookingHead4 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.btn-ticket {
  background: linear-gradient(
    50deg,
    rgba(23, 255, 23, 1),
    rgba(0, 217, 0, 1)
  ) !important;
  border-radius: 7px !important;
  border: 1px solid var(--primary-color) !important;
  padding: 15px 30px !important;
  font-weight: 500 !important;
  font-size: 14px;
}

.spanHead {
  font-size: 24px;
  font-weight: 500;
}

.heading {
  font-size: 24px;
  font-weight: 300;
}

.bcHeader {
  font-size: 24px;
  font-weight: 500;
}

.bcHeader1 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(116, 118, 136, 1);
}

.bcHeader2 {
  font-size: 32px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

.bcHeader3 {
  font-size: 18px;
  font-weight: 500;
  color: rgba(4, 150, 4, 1);
}

.bcHeader4 {
  font-size: 18px;
  font-weight: 500;
  color: rgba(18, 13, 38, 1);
}

.bcHeader5 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(240, 99, 90, 1);
}

.btn-eta {
  border-radius: 8px;
  background: #17ff17;
  border: none;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 8px;
}

.pdHeader {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.pdBooking {
  font-size: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.pdBooking1 {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.pdBooking2 {
  font-size: 20px;
  font-weight: 600;
  color: rgba(33, 136, 33, 1);
}

.pdBooking3 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.pdOffer {
  font-size: 24px;
  font-weight: 500;
  color: rgba(56, 152, 56, 1);
}

.pdOffer1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.pdOfferApply {
  font-size: 16px;
  font-weight: 500;
  color: rgba(56, 152, 56, 1);
  cursor: pointer;
}

.pdBill {
  font-size: 12px;
  font-weight: 500;
  color: rgba(128, 128, 128, 1);
}

.testName {
  font-size: 18px;
  font-weight: 700;
}

.galleryHeader {
  font-size: 36px;
  font-weight: 600;
}

.tourHeading {
  font-size: 32px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
}

.tncHead {
  font-size: 32px;
  font-weight: 700;
}

.no-caret::after {
  display: none !important;
}

.dropdownBorder {
  border-bottom: 1px solid lightgray !important;
}

@media (min-width: 577px) and (max-width: 1200px) {
  .bookingHead4 {
    font-size: 16px;
  }

  .galleryHeader {
    font-size: 35px;
  }
}

@media (max-width: 576px) {
  .imageHeightContainer {
    width: 100%; /* Set width as needed */
    height: 200px; /* Fixed height */
    overflow: hidden; /* Hide the rest of the image */
    border-radius: 12px;
    position: relative;
  }

  .imageHeightContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 62%;
  }

  .bcHeader {
    font-size: 20px;
  }

  .bcHeader1 {
    font-size: 13px;
  }

  .bcHeader2 {
    font-size: 25px;
  }

  .bcHeader3 {
    font-size: 13px;
    text-align: end;
  }

  .bcHeader4 {
    font-size: 13px;
  }

  .bcHeader5 {
    font-size: 13px;
  }

  .btn-eta {
    font-size: 9px;
    padding: 4px 6px;
  }

  .bookingHead1 {
    font-size: 13px;
  }

  .bookingHead2 {
    font-size: 13px;
  }

  .bookingHead3 {
    font-size: 12px;
  }

  .bookingHead4 {
    font-size: 14px;
  }

  .heading {
    font-size: 18px;
  }

  .spanHead {
    font-size: 19px;
  }

  .pdHeader {
    font-size: 30px;
  }

  .pdBooking {
    font-size: 17px;
  }

  .pdBooking1 {
    font-size: 16px;
  }

  .pdBooking2 {
    font-size: 14px;
  }

  .pdBooking3 {
    font-size: 11px;
  }

  .pdOffer {
    font-size: 20px;
  }

  .pdOffer1 {
    font-size: 11px;
  }

  .pdOfferApply {
    font-size: 13px;
  }

  .testName {
    font-size: 15px;
  }

  .galleryHeader {
    font-size: 23px;
  }

  .tourHeading {
    font-size: 28px;
  }

  .tncHead {
    font-size: 25px;
  }
}
