.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 15rem;
  height: 5rem;
  border-width: 0.4rem;
}
