@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:root {
  --primary-color: #17ff17 !important;
  --secondary-color: #ffffff;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner controls in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #555;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, black 18.12%, black 71.56%);
  border-radius: 5px;
}

.dashBorder {
  display: flex;
  align-items: center;
  position: relative;
  /* Ensures pseudo-elements are positioned correctly */
  flex: 1;
  /* Allows the line to stretch */
}

.dashBorder2 {
  display: flex;
  align-items: center;
  position: relative;
  /* Ensures pseudo-elements are positioned correctly */
  flex: 1;
  /* Allows the line to stretch */
}

.dashBorder1 {
  display: flex;
  align-items: center;
  position: relative;
  /* Ensures pseudo-elements are positioned correctly */
  flex: 1;
  /* Allows the line to stretch */
}

.dashBorder::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: gray;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
}

.dashBorder1::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: gray;
  border-radius: 50%;
  margin-left: 5px;
  position: relative;
}

.dashBorder2::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 50%;
  position: relative;
}

.dashBorder2::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 50%;
  position: relative;
}

.dashBorder:before,
.dashBorder2:after,
.dashBorder2:before,
.dashBorder1:after {
  z-index: 1;
}

.dashBorder > .line {
  flex: 1;
  border-top: 2px dashed gray;
}

.dashBorder1 > .line1 {
  flex: 1;
  border-top: 2px dashed gray;
}

.dashBorder2 > .line2 {
  flex: 1;
  border-top: 2px solid rgba(128, 128, 128, 0.75);
}

.contentSection {
  padding-top: 92px;
  /* min-height: 100vh; */
}

.btn-auth {
  background: linear-gradient(
    50deg,
    rgba(23, 255, 23, 1),
    rgba(0, 217, 0, 1)
  ) !important;
  border-radius: 7px !important;
  border: 1px solid var(--primary-color) !important;
  padding: 5px 30px !important;
  font-weight: 500 !important;
  font-size: 14px;
}

.btn-auth-otp {
  border-radius: 7px !important;
  border: 1px solid black !important;
  padding: 5px 30px !important;
  font-weight: 500 !important;
  font-size: 14px;
  background-color: black !important;
  color: white !important;
}

.btn-secondary {
  border-radius: 7px !important;
  border: 1px solid black !important;
  padding: 15px 30px !important;
  font-weight: 500 !important;
  font-size: 14px;
  background-color: black !important;
  color: white !important;
}

.btn-edit {
  border-radius: 7px !important;
  border: 1px solid black !important;
  padding: 8px 30px !important;
  font-weight: 500 !important;
  font-size: 14px;
  background-color: black !important;
  color: white !important;
}

.btn-primary {
  background: var(--primary-color) !important;
  border-radius: 12px !important;
  border: 1px solid var(--primary-color) !important;
  padding: 15px 30px !important;
  font-weight: 500 !important;
  font-size: 16px;
}

.btn-search {
  background: var(--primary-color) !important;
  border-radius: 12px !important;
  border: 1px solid var(--primary-color) !important;
  padding: 15px 30px !important;
  font-weight: 500 !important;
}

.custom-button {
  border-radius: 20px !important;
}

.book-button {
  background: var(--primary-color) !important;
  border-radius: 40px !important;
  border: 1px solid var(--primary-color) !important;
  padding: 10px 18px !important;
  font-weight: 500 !important;
}

.btn-submit {
  background: black !important;
  border-radius: 40px !important;
  border: 1px solid black !important;
  padding: 10px 18px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.view-button {
  background: var(--secondary-color) !important;
  border-radius: 50px !important;
  border: 1px solid var(--secondary-color) !important;
  padding: 5px 20px !important;
  font-weight: 500 !important;
}

.headerContainer {
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.headerBanner {
  background: url("../public/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 80vh; */
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  font-weight: 400;
  position: relative;
}

.abtmapBanner {
  background: url("../public/images/aboutMap.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  /* padding: 0px 0px; */
}

.contactBanner {
  background: url("../public/images/contactBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 180px 0px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  /* background-position: right; */
  color: white;
}

.aboutBanner {
  background: url("../public/images/aboutusBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 180px 0px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  /* background-position: right; */
  color: white;
}

.galleryBanner {
  background: url("../public/images/galleryBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 150px 0px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  /* background-position: right; */
  color: white;
}

.bookingBanner {
  background: url("../public/images/bookingBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 120px 0px;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  /* background-position: right; */
  color: white;
}

.contactText {
  font-size: 48px;
  font-weight: 700;
}

.mainLogo {
  width: 150px;
}

.cardLogo {
  width: 90px;
}

.mainCard {
  width: 9rem;
}

.cardText {
  font-size: 16px;
}

.destHead {
  font-size: 24px;
  font-weight: 600;
}

.hover-card {
  transition: transform 0.1s ease-in-out;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(100px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}

.hover-card:hover {
  transform: scale(1.15);
  /* Slightly bulge the card on hover */
}

/* .featureCard {
  padding-top: 350px;
} */

.bottomContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  bottom: 40px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.environmentBanner {
  background: url("../public/images/environment.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  color: white;
  padding: 20px;
  background-position: right;
}

.appInfoBanner {
  background: url("../public/images/appinfo.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  color: white;
  padding: 0px 0px;
  /* background-position: right; */
  /* margin-top: 10px; */
}

.testimonialBanner {
  background: url("../public/images/testimonial.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  color: white;
  padding: 50px;
  background-position: right;
}

.footerBanner {
  background: url("../public/images/footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  color: white;
  padding: 20px;
  background-position: right;
}

.footerBack {
  position: sticky;
}

.footerLogo {
  width: 180px;
}

.footerHeader {
  font-size: 16px;
  font-weight: 600;
}

.footerLabel {
  color: rgba(158, 150, 150, 1);
  font-size: 16px;
}

.contHeader {
  font-size: 40px;
  font-weight: 700;
}

.abtHeader {
  font-size: 48px;
  font-weight: 700;
}

.abtHeader2 {
  font-size: 32px;
  font-weight: 600;
}

.contSubHeader {
  font-size: 16px;
  font-weight: 400;
}

.marqueeBanner {
  background: url("../public/images/img6.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-weight: 400;
  color: white;
  padding: 40px;
  background-position: right;
  color: black;
}

.seenLogo {
  width: 300px;
}

.marqueeContainer {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.marquee {
  display: flex;
  align-items: center;
  animation: marquee 18s linear infinite;
}

.marquee img {
  width: auto;
  max-width: 50%;
  height: auto;
  flex-shrink: 0;
  margin-right: 1rem;
}

.shadow-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  /* max-width: 350px; */
}

.featureSlider {
  border-radius: 14px;
  padding: 20px;
  height: 100%;
  width: 100%;
  max-width: 357px;
  margin: 0 auto;
}

.bottomSlider {
  background: white;
  border-radius: 32px;
  padding: 20px;
  max-width: 370px;
  margin: 2px;
  /* color: rgba(115, 115, 115, 1); */
}

.clipBackground {
  background-image: url("/public/images/clipboard.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 14px;
  height: 100%;
  width: 55%;
}

.tnC {
  border-right: 1px solid rgba(233, 233, 233, 0.62);
}

.bottomSlider1 {
  background-image: url("/public/images/slider1.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;
  height: 100%;
  width: 100%;
  max-width: 388px;
  padding: 20px;
  border-radius: 16px;
}

.bottomSlider2 {
  background-image: url("/public/images/slider2.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;
  height: 100%;
  width: 100%;
  max-width: 388px;
  padding: 20px;
  border-radius: 16px;
}

.bottomSlider3 {
  background-image: url("/public/images/slider3.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;
  height: 100%;
  width: 100%;
  max-width: 388px;
  padding: 20px;
  border-radius: 16px;
}

/* Slick SLider---- */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  display: block;
  align-items: center;
  justify-content: center;
  /* opacity: 0.75; */
  color: rgba(0, 0, 0, 1);
  cursor: pointer;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 576px) {
  .slick-prev:before,
  .slick-next:before {
    display: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -10px;
}

[dir="rtl"] .slick-prev {
  right: -10px;
  left: auto;
}

.slick-prev:before {
  content: "←";
  font-weight: bolder;
}

[dir="rtl"] .slick-prev:before {
  content: "→";
  font-weight: bolder;
}

.slick-next {
  right: -10px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
  font-weight: bolder;
}

[dir="rtl"] .slick-next:before {
  content: "←";
  font-weight: bolder;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  /* position: absolute; */
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}

.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: darkgreen;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 10;
  color: darkgreen(0, 0, 0, 1);
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: block;
}

.authHeader {
  font-size: 24px;
  font-weight: 600;
}

.authSubHeader {
  font-size: 12px;
  font-weight: 400;
  color: rgba(93, 93, 93, 1);
}

.authLabels {
  font-size: 14px;
  font-weight: 500;
}

.bookHeader {
  font-size: 18px;
  font-weight: 600;
}

.bookHeader2 {
  font-size: 24px;
  font-weight: 300;
}

.profileLabels {
  font-size: 18px;
  font-weight: 500;
}

.profileLabels1 {
  font-size: 15px;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .marquee img {
    max-width: 25%;
    margin-right: 0.75rem;
  }
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .marquee img {
    max-width: 40%;
    margin-right: 0.5rem;
  }
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .contactBanner {
    padding: 100px 0px;
  }

  .aboutBanner {
    padding: 100px 0px;
  }

  .galleryBanner {
    padding: 130px 0px;
  }

  .bookingBanner {
    padding: 70px 0px;
  }

  .contactText {
    font-size: 40px;
  }

  .environmentBanner h2 {
    font-size: 29px;
  }
}

@media (min-width: 1201px) {
  .environmentBanner h1 {
    font-size: 100px;
  }

  .footerLogo {
    width: 185px;
  }

  .seenLogo {
    width: 300px;
  }

  .featureCard {
    padding: 340px 0px 75px 0;
  }

  .cardLogo {
    width: 100px;
  }

  .mainCard {
    width: 10rem;
  }

  .btn-search {
    padding: 15px 10px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contactBanner {
    padding: 80px 0px;
  }

  .aboutBanner {
    padding: 80px 0px;
  }

  .galleryBanner {
    padding: 90px 0px;
  }

  .bookingBanner {
    padding: 35px 0px;
  }

  .contactText {
    font-size: 40px;
  }

  .environmentBanner {
    background-position: center;
    height: auto;
    padding: 30px 15px;
  }

  .appInfoBanner {
    background-position: center;
    height: auto;
    padding: 30px;
  }

  .environmentBanner h1 {
    font-size: 80px;
  }

  .environmentBanner h2 {
    font-size: 24px;
  }

  .environmentBanner ul {
    font-size: 18px;
  }

  .environmentBanner img {
    width: 100%;
  }

  .footerLogo {
    width: 175px;
  }

  .seenLogo {
    width: 250px;
  }

  .featureCard {
    padding: 100px 0px;
  }

  .mainCard {
    width: 8rem;
  }

  .btn-search {
    padding: 10px 10px !important;
  }

  .destHead {
    font-size: 19px;
  }

  .book-button {
    padding: 7px 9px !important;
  }

  .abtHeader {
    font-size: 31px;
    font-weight: 700;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .tnC {
    border: none;
  }

  .environmentBanner {
    background-position: center;
    height: auto;
    padding: 30px 15px;
  }

  .appInfoBanner {
    background-position: center;
    height: auto;
    padding: 30px 15px;
  }

  .environmentBanner h1 {
    font-size: 80px;
  }

  .environmentBanner h2 {
    font-size: 27px;
  }

  .environmentBanner ul {
    font-size: 18px;
  }

  .environmentBanner img {
    width: 100%;
  }

  .footerLogo {
    width: 155px;
  }

  .seenLogo {
    width: 255px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contentSection {
    padding-top: 76px;
  }

  .contactBanner {
    padding: 60px 0px;
  }

  .aboutBanner {
    padding: 60px 0px;
  }

  .galleryBanner {
    padding: 75px 0px;
  }

  .bookingBanner {
    padding: 60px 0px;
  }

  .contactText {
    font-size: 35px;
  }

  .mainCard {
    width: 8.5rem;
  }

  .featureCard {
    padding: 100px 0px;
  }

  .bottomContainer {
    bottom: 60px;
  }

  .btn-search {
    padding: 10px 15px !important;
  }

  .destHead {
    font-size: 19px;
  }

  .abtHeader {
    font-size: 27px;
    font-weight: 700;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .tnC {
    border: none;
  }

  .contactBanner {
    padding: 40px 0px;
  }

  .aboutBanner {
    padding: 40px 0px;
  }

  .galleryBanner {
    padding: 50px 0px;
  }

  .bookingBanner {
    padding: 40px 0px;
  }

  .contentSection {
    padding-top: 67px;
  }

  .contactText {
    font-size: 25px;
  }

  .featureCard {
    padding: 80px 0px;
  }

  .mainCard {
    width: 8rem;
  }

  .btn-search {
    padding: 10px 30px !important;
  }

  .abtHeader {
    font-size: 27px;
    font-weight: 700;
  }
}

@media (max-width: 576px) {
  .profileLabels {
    font-size: 16px;
  }

  .profileLabels1 {
    font-size: 12px;
  }
  .authHeader {
    font-size: 22px;
  }

  .tnC {
    border: none;
  }

  .contentSection {
    padding-top: 40px;
  }

  .contactBanner {
    padding: 30px 0px;
  }

  .aboutBanner {
    padding: 30px 0px;
  }

  .galleryBanner {
    padding: 35px 0px;
  }

  .bookingBanner {
    padding: 30px 0px;
  }

  .contactText {
    font-size: 19px;
  }

  .headerContainer {
    padding: 5px;
  }

  .environmentBanner {
    padding: 20px 10px;
  }

  .appInfoBanner {
    padding: 20px 10px;
  }

  .environmentBanner h1 {
    font-size: 50px;
  }

  .environmentBanner h2 {
    font-size: 20px;
  }

  .environmentBanner ul {
    font-size: 14px;
  }

  .mainLogo {
    width: 60px;
  }

  .footerLogo {
    width: 130px;
  }

  .seenLogo {
    width: 200px;
  }

  .cardLogo {
    width: 45px;
  }

  .mainCard {
    width: 6rem;
  }

  .featureCard {
    padding: 80px 0px;
  }

  .testimonialBanner {
    padding: 20px;
  }

  .btn-primary {
    padding: 10px 30px !important;
    margin-bottom: 10px;
  }

  .btn-edit {
    padding: 6px 20px !important;
  }

  .footerBanner {
    padding: 1px;
  }

  .footerLabel {
    font-size: 14px;
  }

  .marqueeBanner {
    padding: 20px;
  }

  .bottomContainer {
    justify-content: center;
  }

  .btn-search {
    padding: 10px 30px !important;
  }

  .cardText {
    font-size: 10px;
  }

  .contHeader {
    font-size: 25px;
    font-weight: 600;
  }

  .abtHeader {
    font-size: 25px;
    font-weight: 700;
  }

  .btn-secondary {
    padding: 12px 30px !important;
  }

  .destHead {
    font-size: 20px;
  }
}
